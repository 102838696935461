import {DEFAULT_ENTITY_NAMED_LIST, DEFAULT_PAGE_SIZE} from 'app/app.constants';
// import { SortDirection } from 'app/blocks//util/sort-direction';
import {EntityView} from 'app/blocks/util/entity-view';

import {ListViewParams} from 'app/blocks/util/list-view-params';
import {PartialEntityStateModel} from 'app/blocks/store/state-model/partial-entity-state-model';
// import { FilterParams } from 'app/blocks/util/filter-params';

export interface InlineEntityStateModel<T> extends PartialEntityStateModel<T> {
    listView: {
        initialized: boolean;
        entityList: T[];
        totalItems: number;
        viewParams: ListViewParams;
        statusLoading: boolean;
        statusSaving: boolean;
        statusAdding: boolean;
        statusUserInput: boolean;
        selectedIds: number[];
        // filters: FilterParams[]
    };
    currentView: EntityView;
}

function listViewParams(desc = false, field = 'id'): ListViewParams {
    return {
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
        sortColumn: field,
        sortDirection: desc ? 'desc' : 'asc',
        filter: '',
        filterParams: []
    } as ListViewParams;
}

export const defaultInlineEntityState = (entityCreator: (entity?) => any, reverse = false, field = 'id') => {
    return {
        listView: {
            initialized: false,
            entityList: [],
            totalItems: 0,
            viewParams: listViewParams(reverse, field),
            statusLoading: false,
            statusSaving: false,
            statusAdding: false,
            statusUserInput: false,
            selectedIds: []
            // filters: []
        },
        partialEntity: {
            [DEFAULT_ENTITY_NAMED_LIST]: {
                list: [],
                loaded: false
            }
        },
        currentView: EntityView.LIST
    };
};
