import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ContactInfo, IContactInfo} from 'app/blocks/model/contact-info.model';
import {ICurrency} from 'app/blocks/model/currency.model';
import {Schedule} from 'app/blocks/model/schedule.model';

export interface IAlert extends IBaseEntity {
    id?: number;
    name?: string;
    created?: any;
    alertType?: any;
    createdBy?: any;
    alertTrigger?: any;
    alertNotifications?: any;
}

export class Alert extends BaseEntity implements IAlert {
    id?: number;
    name?: string;
    created?: any;
    alertType?: any;
    createdBy?: any;
    alertTrigger?: any;
    alertNotifications?: any;

    constructor(alert?) {
        super();
        if (alert) {
            this.id = alert.id;
            this.name = alert.name;
            this.created = alert.created;
            this.alertType = alert.alertType;
            this.createdBy = alert.createdBy;
            this.alertTrigger = alert.alertTrigger;
            this.alertNotifications = alert.alertNotifications;
        }
    }

    clone(): Alert {
        return new Alert(this);
    }

    get discriminator(): any {
        return this.name;
    }
}
