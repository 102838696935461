import {IProfile, Profile} from 'app/blocks/model/profile.model';
import {IRole, Role} from 'app/blocks/model/role.model';
import {ISafetyProfile, SafetyProfile} from 'app/blocks/model/safety-profile.model';
import {ITenant, Tenant} from 'app/blocks/model/tenant.model';
import {DriverAccessCodes} from 'app/blocks/model/driver-access-codes.model';
import {IDailyActivitySummary, DailyActivitySummary} from 'app/blocks/model/daily-activity-summary.model';
import {ISGSTCategory} from 'app/blocks/model/sgst-category.model';
import {ICGSTCategory} from 'app/blocks/model/cgst-category.model';
import {IFuelSurchargeGroup, FuelSurchargeGroup} from 'app/blocks/model/fuel-surcharge-group.model';
import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {ListViewParams} from 'app/blocks/util/list-view-params';
import {AlertService} from 'app/common/alert/alert.service';
import {Resource} from 'app/constants/resource';
import produce from 'immer';
import * as _ from 'lodash';

import {Branch, IBranch} from 'app/blocks/model/branch.model';
import {ICountry} from 'app/blocks/model/country.model';

import {IWarehouse, Warehouse} from 'app/blocks/model/warehouse.model';
import {IWarehouseType} from 'app/blocks/model/warehouse-type.model';
import {Company, ICompany} from 'app/blocks/model/company.model';
import {ICurrency} from 'app/blocks/model/currency.model';

import {IMajorAccountCode, MajorAccountCode} from 'app/blocks/model/major-account-code.model';
import {Employee, IEmployee} from 'app/blocks/model/employee.model';
import {EmployeeGroup, IEmployeeGroup} from 'app/blocks/model/employee-group.model';

import {IServicePriorityCode, ServicePriorityCode} from 'app/blocks/model/service-priority-code.model';
import {DepositItem, IDepositItem} from 'app/blocks/model/deposit-item.model';
import {IStatementCode, StatementCode} from 'app/blocks/model/statement-code.model';
import {IStatementType} from 'app/blocks/model/statement-type.model';
import {IBillingFrequencyType} from 'app/blocks/model/billing-frequency-type.model';
import {DunningMessageCode, IDunningMessageCode} from 'app/blocks/model/dunning-message-code.model';
import {CreditClass, ICreditClass} from 'app/blocks/model/credit-class.model';
import {CreditTerm, ICreditTerm} from 'app/blocks/model/credit-term.model';
import {DeliveryPoint, IDeliveryPoint} from 'app/blocks/model/delivery-point.model';
import {IDynamicHeaderCode} from 'app/blocks/model/dynamic-header-code.model';
import {IStopType, StopType} from 'app/blocks/model/stop-type.model';
import {IDeliveryPointMessageType} from 'app/blocks/model/delivery-point-message-type.model';
import {Customer, ICustomer} from 'app/blocks/model/customer.model';
import {IPaymentType, PaymentType} from 'app/blocks/model/payment-type.model';
import {ICustomerStatus} from 'app/blocks/model/customer-status.model';
import {ICustomerStatusReason} from 'app/blocks/model/customer-status-reason.model';
import {ICustomerStatusReasonGroup} from 'app/blocks/model/customer-status-reason-group.model';
import {CustomerGroup, ICustomerGroup} from 'app/blocks/model/customer-group.model';
import {PriceList, IPriceList} from 'app/blocks/model/price-list.model';
import {IPriceListType} from 'app/blocks/model/price-list-type.model';
import {IDurationPriceList} from 'app/blocks/model/duration-price-list.model';
import {IListProductPrice} from 'app/blocks/model/list-product-price.model';
import {CustomerSmsText, ICustomerSmsText} from 'app/blocks/model/customer-sms-text.model';

import {IProductClass, ProductClass} from 'app/blocks/model/product-class.model';
import {IProductGroup, ProductGroup} from 'app/blocks/model/product-group.model';
import {IUnitOfMeasure, UnitOfMeasure} from 'app/blocks/model/unit-of-measure.model';
import {IPackaging, Packaging} from 'app/blocks/model/packaging.model';
import {IProductPackaging} from 'app/blocks/model/product-packaging.model';
import {IProduct, Product} from 'app/blocks/model/product.model';
import {IProductList, ProductList} from 'app/blocks/model/product-list.model';
import {IProductTaxCategory, ProductTaxCategory} from 'app/blocks/model/product-tax-category.model';
import {IDurationProductPriceInfo} from 'app/blocks/model/duration-product-price-info.model';
import {DeliveryPackageType, IDeliveryPackageType} from 'app/blocks/model/delivery-package-type.model';

import {IVehicle, Vehicle} from 'app/blocks/model/vehicle.model';
import {IVehicleType, VehicleType} from 'app/blocks/model/vehicle-type.model';
import {ITrailer, Trailer} from 'app/blocks/model/trailer.model';
import {ITrailerType, TrailerType} from 'app/blocks/model/trailer-type.model';
import {IRouteType, RouteType} from 'app/blocks/model/route-type.model';
import {IRouteMapSymbol, RouteMapSymbol} from 'app/blocks/model/route-map-symbol.model';
import {IRoute, Route} from 'app/blocks/model/route.model';

import {ISaleOrder, SaleOrder} from 'app/blocks/model/sale-order.model';
import {ISaleOrderType} from 'app/blocks/model/sale-order-type.model';
import {IDeliveryMethod} from 'app/blocks/model/delivery-method.model';
import {IOrderPriority} from 'app/blocks/model/order-priority.model';
import {IOrderStatusReason} from 'app/blocks/model/order-status-reason.model';
import {IOrderItem} from 'app/blocks/model/order-item.model';
import {IOrderRejectionCode, OrderRejectionCode} from 'app/blocks/model/order-rejection-code.model';
import {IIncomingInventory, IncomingInventory, IWarehouseTransfer, WarehouseTransfer} from 'app/blocks/model/inventory-movement.model';
import {IInventoryProductEntry} from 'app/blocks/model/inventory-product-entry.model';
import {IInventoryReason} from 'app/blocks/model/inventory-reason.model';
import {IInventoryReasonGroup} from 'app/blocks/model/inventory-reason-group.model';
import {ILoadOrder, LoadOrder} from 'app/blocks/model/load-order.model';
import {IDeliveryItem} from 'app/blocks/model/delivery-item.model';
import {IDeliveryPackage} from 'app/blocks/model/delivery-package.model';
import {IPackOrder, PackOrder} from 'app/blocks/model/pack-order.model';
import {IPickOrder, PickOrder} from 'app/blocks/model/pick-order.model';
import {IOrderItemReason} from 'app/blocks/model/order-item-reason.model';
import {DeliveryOrder, IDeliveryOrder} from 'app/blocks/model/delivery-order.model';
import {IInventoryTransaction, InventoryTransaction} from 'app/blocks/model/inventory-transaction.model';

import {HandheldProfile, IHandheldProfile} from 'app/blocks/model/handheld-profile.model';
import {defaultFilterParams} from 'app/constants/default-filter-params';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {EntityActionHandler} from 'app/blocks/store/action-handler/entity-action-handler';
import {
    AbstractGetNamedEntityList,
    IEntityAction,
    ChangeEntityListViewParams,
    CreateEntity,
    GetEntityList,
    GetNamedEntityList,
    GetNewEntity,
    GetNewEntityWithSettings,
    IGetNewEntityWithSettings,
    ISelectAllCurrentPageEntities,
    ISelectEntity,
    ISelectEntityTabIndex,
    IUnselectAllCurrentPageEntities,
    IUnselectEntity,
    SelectAllCurrentPageEntities,
    SelectEntity,
    SelectEntityTabIndex,
    UnselectAllCurrentPageEntities,
    UnselectEntity,
    UpdateEntity,
    ExportEntities,
    IExportEntities,
    UpdateInlineEntity,
    StartInlineEntityUpdate,
    CancelInlineEntityUpdate,
    ICancelInlineEntityUpdate,
    IStartInlineEntityUpdate,
    IStartInlineEntityAdd,
    StartInlineEntityAdd,
    CreateInlineEntity,
    ICreateInlineEntity,
    IUpdateInlineEntity,
    IDuplicateEntity,
    DuplicateEntity
} from 'app/blocks/store/actions/entity-actions';
import {
    DeleteEntity,
    GetEntity,
    IChangeEntityListViewParams,
    ICreateEntity,
    IDeleteEntity,
    IGetEntity,
    IGetEntityList,
    IGetNewEntity,
    IUpdateEntity
} from 'app/blocks/store/actions/entity-actions';
import {defaultEntityState, defaultEntityStateWithDefaultFilters, EntityStateModel} from 'app/blocks/store/state-model/entity-state-model';
import {defaultInlineEntityState, InlineEntityStateModel} from 'app/blocks/store/state-model/inline-entity-state-model';
import {defaultPartialEntityState, PartialEntityStateModel} from 'app/blocks/store/state-model/partial-entity-state-model';
import {SettingMetaDataService} from 'app/blocks/service/api/setting-meta-data.service';
import {
    CompletePackOrder,
    ConfirmSaleOrder,
    ProcessLoadOrder,
    ReceiveInventory,
    StartPackOrder,
    StartPickOrder,
    ValidatePickOrder,
    CancelSaleOrder,
    ConfirmPurchaseOrder,
    CancelPurchaseOrder,
    ReceivePurchaseOrder,
    ConfirmServiceOrder,
    CreateServiceOrder,
    ICreateServiceOrder,
    CancelServiceOrder,
    UpdateServiceOrder,
    IUpdateServiceOrder,
    MarkPickOrderAsPending,
    ServicedServiceOrder,
    UncancelSaleOrder,
    CancelLoadOrder
} from 'app/blocks/store/actions/inventory-actions';
import {MarkInvoiceAsReady, InvoiceCustomer, CancelInvoice, UnCancelInvoice, UpdateInvoiceAllocated} from 'app/blocks/store/actions/invoice-actions';
import {IncomingInventoryService} from 'app/blocks/service/api/incoming-inventory.service';
import {SaleOrderService} from 'app/blocks/service/api/sale-order.service';
import {PickOrderService} from 'app/blocks/service/api/pick-order.service';
import {PackOrderService} from 'app/blocks/service/api/pack-order.service';
import {CompanyService} from 'app/blocks/service/api/company.service';
import {GetCompanyEntityOnLoadWithoutId, IGetCompanyEntityOnLoadWithoutId} from 'app/blocks/store/actions/company-actions';
import {LoadOrderService} from 'app/blocks/service/api/load-order.service';
import {IInvoice, Invoice} from 'app/blocks/model/invoice.model';
import {InvoiceService} from 'app/blocks/service/api/invoice.service';
import {ICreditNote, CreditNote} from 'app/blocks/model/credit-note.model';
import {IHaulierOrder, HaulierOrder} from 'app/blocks/model/haulier-order.model';
import {ISiteVisit, SiteVisit} from 'app/blocks/model/site-visit.model';
import {VehicleInspectionReport, IVehicleInspectionReport} from 'app/blocks/model/vehicle-inspection-report.model';
import {IDriverEodReport, DriverEodReport} from 'app/blocks/model/driver-eod-report.model';
import {Payment} from 'app/blocks/model/payment.model';
import {saveAs} from 'file-saver';
import {DEBUG_PAGE_PARAMS} from 'app/app.constants';
import {IOrganisation, Organisation} from 'app/blocks/model/organisation.model';
import {IStockTake, StockTake} from 'app/blocks/model/stock-take.model';
import {UpdateSaleOrder, IUpdateSaleOrder, CreateSaleOrder, ICreateSaleOrder} from 'app/blocks/store/actions/sale-order-actions';
import {IMposfile} from 'app/blocks/model/mpos-file.model';
import {IUpdateProduct, UpdateProduct, ICreateProduct, CreateProduct} from 'app/blocks/store/actions/product-actions';
import {ProductService} from 'app/blocks/service/api/product.service';
import {IVehicleUnload, VehicleUnload} from 'app/blocks/model/vehicle-unload.model';

import {Supplier, ISupplier} from 'app/blocks/model/supplier.model';
import {IServiceItem, ServiceItem} from 'app/blocks/model/service-item.model';
import {IEquipment, Equipment} from 'app/blocks/model/equipment.model';
import {IServiceOrder, ServiceOrder} from 'app/blocks/model/service-order.model';
import {ServiceOrderService} from 'app/blocks/service/api/service-order.service';
import {StandingOrder} from 'app/blocks/model/standing-order.model';
import {SaleOrderStatus} from 'app/blocks/model/sale-order-status';
import {IReportData, ReportData} from 'app/blocks/model/report-data.model';
import {UpdatePaymentAllocated} from 'app/blocks/store/actions/payment-actions';
import {IPayment} from 'app/blocks/model/payment.model';
import {IClientSaleOrder, ClientSaleOrder} from 'app/blocks/model/client-sale-order.model';
import {PurchaseOrder, IPurchaseOrder} from 'app/blocks/model/purchase-order.model';
import {ICreatePurchaseOrder, CreatePurchaseOrder, IUpdatePurchaseOrder, UpdatePurchaseOrder} from 'app/blocks/store/actions/purchase-order-actions';
import {PurchaseOrderService} from 'app/blocks/service/api/purchase-order.service';
import {PurchaseOrderStatus} from 'app/blocks/model/purchase-order-status';
import {IIGSTCategory} from 'app/blocks/model/igst-category.model';
import {ICustomerTransaction, CustomerTransaction} from 'app/blocks/model/customer-transaction.model';
import {IDriverAccessCodes} from 'app/blocks/model/driver-access-codes.model';
import {UpdateCreditNoteAllocated} from 'app/blocks/store/actions/credit-note-actions';
import {Principal} from 'app/core/auth/principal.service';
import {ISurvey, Survey} from 'app/blocks/model/survey.model';
import {Target, ITarget} from 'app/blocks/model/target.model';
import {IReasonCode, ReasonCode} from 'app/blocks/model/reason-code.model';
import {IPromotion, Promotion} from 'app/blocks/model/promotion.model';
import {IUserEntity, UserEntity} from 'app/blocks/model/user.model';
import {EodSurvey, IEodSurvey} from 'app/blocks/model/eod-survey.model';
import {IProductImeiTrack, ProductImeiTrack} from 'app/blocks/model/product-imei-track.model';
import {IWorkshop, Workshop} from 'app/blocks/model/workshop.model';
import {IRecipe, Recipe} from 'app/blocks/model/recipe.model';
import {IRecipeExecutionReport, RecipeExecutionReport} from 'app/blocks/model/recipe-execution-report.model';
import {ITemperatureReport, TemperatureReport} from 'app/blocks/model/temperature-report.model';
import {IStockChangeCondition, StockChangeCondition} from 'app/blocks/model/stock-change-condition.model';
import {CreateIncomingInventory, IUpdateIncomingInventory, UpdateIncomingInventory} from 'app/blocks/store/actions/incoming-inventory-actions';
import {IPurchaseInvoice, PurchaseInvoice} from 'app/blocks/model/purchase-invoice.model';
import {ISchedule, Schedule} from 'app/blocks/model/schedule.model';
import {ScheduleService} from 'app/blocks/service/api/schedule.service';
import {IRestore, Restore} from 'app/blocks/model/restore.model';
import {Backup, IBackup} from 'app/blocks/model/backup.model';
import {Execution, IExecution} from 'app/blocks/model/execution.model';
import {Alert, IAlert} from 'app/blocks/model/alert.model';
import {RETAIN_PREVIOUS_SCREEN_FILTERS} from 'app/constants/setting';
import {IShortfallReport, ShortfallReport} from 'app/blocks/model/shortfall-report.model';
import {SuperUserService} from 'app/core/auth/super-user.service';
import {IProductCostChange, ProductCostChange} from 'app/blocks/model/product-cost-change.model';
import {IProductionVoucher, ProductionVoucher} from 'app/blocks/model/production-voucher.model';
import {EodInventoryReport, IEodInventoryReport} from 'app/blocks/model/eod-inventory-report.model';
import {ISurveyReport, SurveyReport} from 'app/blocks/model/survey-report.model';
import {AppVersion, IAppVersion} from 'app/blocks/model/app-version.model';
import {DefaultProductPriceChange, IDefaultProductPriceChange} from 'app/blocks/model/default-product-price-change.model';
import {ISiteVisitSkipReasons, SiteVisitSkipReasons} from 'app/blocks/model/site-visit-skip-reasons.model';
import {CustomFieldGroup, ICustomFieldGroup} from 'app/blocks/model/custom-field-group.model';
import {CustomField, ICustomField} from 'app/blocks/model/custom-field.model';
import {EmailWorkflow, IEmailWorkflow} from 'app/blocks/model/email-workflow.model';
import {AsyncRequest, IAsyncRequest} from 'app/blocks/model/async_request.model';
import {BranchPayment, IBranchPayment} from 'app/blocks/model/branch-payment.model';
import {BranchTransaction, IBranchTransaction} from 'app/blocks/model/branch-transaction.model';
import {CreatePurchaseInvoice, IUpdatePurchaseInvoice, UpdatePurchaseInvoice} from 'app/blocks/store/actions/purchase-invoice-actions';
import {PurchaseInvoiceService} from 'app/blocks/service/api/purchase-invoice.service';
import {IPurchasePayment, PurchasePayment} from 'app/blocks/model/purchase-payment.model';
import {IPurchaseCreditNote, PurchaseCreditNote} from 'app/blocks/model/purchase-credit-note.model';
import {PurchaseCreditNoteService} from 'app/blocks/service/api/purchase-credit-note.service';
import {IProductAttributeType, ProductAttributeType} from 'app/blocks/model/product-attribute-type.model';
import {CatalogueEntity, ICatalogueEntity} from 'app/blocks/model/catalogue.model';
import {CataloguePresentation, ICataloguePresentation} from 'app/blocks/model/presentation.model';
import {CatalogueResourceItem, ICatalogueResourceItem} from 'app/blocks/model/resource-item.model';
import {ISupplierPaymentTerm, SupplierPaymentTerm} from 'app/blocks/model/supplier-payment-term.model';
import {EmployeeExpenses} from 'app/blocks/model/employee-expenses';
import {ExpensePaymentMethod, IExpensePaymentMethod} from 'app/blocks/model/expense-payment-method';
import {ExpenseType, IExpenseType} from 'app/blocks/model/expense-type.model';
import {IImage} from 'app/blocks/model/image.model';
import {Brand, IBrand} from 'app/blocks/model/brand.model';
import {IIntegrationMapping, IntegrationMapping} from 'app/blocks/model/integration-mapping.model';
import {ISupplierTransaction, SupplierTransaction} from 'app/blocks/model/supplier-transaction.model';
import {DbUser, IDbUser} from 'app/blocks/model/dbuser.model';
import {IVehicleSkill, VehicleSkill} from 'app/blocks/model/vehicle-skill.model';
import {CatalogueResourceGroup, ICatalogueResourceGroup} from 'app/blocks/model/resource-group.model';
import {BinType} from 'app/blocks/model/bin-type.model';
import {Bin} from 'app/blocks/model/bin.model';
import {CustomPdfTemplate, ICustomPdfTemplate} from 'app/blocks/model/custom-pdf-template.model';
import {ISupplierGroup, SupplierGroup} from 'app/blocks/model/supplier-group.model';
import {Budget, IBudget} from 'app/blocks/model/budget.model';

export interface EntitiesStateModel {
    [Resource.DAILY_ACTIVITY_SUMMARIES]: EntityStateModel<IDailyActivitySummary>;
    [Resource.BRANCHES]: EntityStateModel<IBranch>;
    [Resource.COUNTRIES]: PartialEntityStateModel<ICountry>;
    [Resource.WAREHOUSES]: EntityStateModel<IWarehouse>;
    [Resource.WAREHOUSE_TYPES]: PartialEntityStateModel<IWarehouseType>;
    [Resource.COMPANIES]: EntityStateModel<ICompany>;
    [Resource.CURRENCIES]: PartialEntityStateModel<ICurrency>;
    [Resource.MAJOR_ACCOUNT_CODES]: InlineEntityStateModel<IMajorAccountCode>;
    [Resource.EMPLOYEES]: EntityStateModel<IEmployee>;
    [Resource.EMPLOYEE_GROUPS]: InlineEntityStateModel<IEmployeeGroup>;
    [Resource.SERVICE_PRIORITY_CODES]: EntityStateModel<IServicePriorityCode>;
    [Resource.CUSTOM_FIELD_GROUPS]: EntityStateModel<ICustomFieldGroup>;
    [Resource.PRODUCT_ATTRIBUTE_TYPES]: EntityStateModel<IProductAttributeType>;
    [Resource.CUSTOM_FIELDS]: EntityStateModel<ICustomField>;
    [Resource.DEPOSIT_ITEMS]: InlineEntityStateModel<IDepositItem>;
    [Resource.STATEMENT_CODES]: EntityStateModel<IStatementCode>;
    [Resource.STATEMENT_TYPES]: PartialEntityStateModel<IStatementType>;
    [Resource.BILLING_FREQUENCY_TYPES]: PartialEntityStateModel<IBillingFrequencyType>;
    [Resource.DUNNING_MESSAGE_CODES]: EntityStateModel<IDunningMessageCode>;
    [Resource.CREDIT_CLASSES]: EntityStateModel<ICreditClass>;
    [Resource.CREDIT_TERMS]: InlineEntityStateModel<ICreditTerm>;
    [Resource.DELIVERY_POINTS]: EntityStateModel<IDeliveryPoint>;
    [Resource.DYNAMIC_HEADER_CODES]: PartialEntityStateModel<IDynamicHeaderCode>;
    [Resource.STOP_TYPES]: EntityStateModel<IStopType>;
    [Resource.DELIVERY_POINT_MESSAGE_TYPES]: PartialEntityStateModel<IDeliveryPointMessageType>;
    [Resource.CUSTOMERS]: EntityStateModel<ICustomer>;
    [Resource.PAYMENT_TYPES]: InlineEntityStateModel<IPaymentType>;
    [Resource.CUSTOMER_STATUSES]: PartialEntityStateModel<ICustomerStatus>;
    [Resource.CUSTOMER_STATUS_REASONS]: PartialEntityStateModel<ICustomerStatusReason>;
    [Resource.CUSTOMER_STATUS_REASON_GROUPS]: PartialEntityStateModel<ICustomerStatusReasonGroup>;
    [Resource.CUSTOMER_GROUPS]: InlineEntityStateModel<ICustomerGroup>;
    [Resource.CUSTOMER_SMS_TEXTS]: InlineEntityStateModel<ICustomerSmsText>;
    [Resource.PROMOTIONS]: EntityStateModel<IPromotion>;
    [Resource.PROFILES]: EntityStateModel<IProfile>;
    [Resource.PRICE_LISTS]: EntityStateModel<IPriceList>;
    [Resource.PRICE_LIST_TYPES]: PartialEntityStateModel<IPriceListType>;
    [Resource.DURATION_PRICE_LISTS]: PartialEntityStateModel<IDurationPriceList>;
    [Resource.LIST_PRODUCT_PRICES]: PartialEntityStateModel<IListProductPrice>;
    [Resource.SUPPLIERS]: EntityStateModel<ISupplier>;
    [Resource.VEHICLE_UNLOADS]: EntityStateModel<IVehicleUnload>;
    [Resource.PRODUCT_CLASSES]: InlineEntityStateModel<IProductClass>;
    [Resource.PRODUCT_GROUPS]: InlineEntityStateModel<IProductGroup>;
    [Resource.UNIT_OF_MEASURES]: EntityStateModel<IUnitOfMeasure>;
    [Resource.PACKAGINGS]: InlineEntityStateModel<IPackaging>;
    [Resource.PRODUCT_PACKAGINGS]: PartialEntityStateModel<IProductPackaging>;
    [Resource.PRODUCTS]: EntityStateModel<IProduct>;
    [Resource.PRODUCT_LISTS]: EntityStateModel<IProductList>;
    [Resource.UK_VAT_RATES]: EntityStateModel<IProductTaxCategory>;
    [Resource.ROI_VAT_RATES]: EntityStateModel<IProductTaxCategory>;
    [Resource.DURATION_PRODUCT_PRICE_INFOS]: PartialEntityStateModel<IDurationProductPriceInfo>;
    [Resource.DELIVERY_PACKAGE_TYPES]: InlineEntityStateModel<IDeliveryPackageType>;
    [Resource.ROLES]: EntityStateModel<IRole>;
    [Resource.VEHICLES]: EntityStateModel<IVehicle>;
    [Resource.TRAILERS]: EntityStateModel<ITrailer>;
    [Resource.TRAILER_TYPES]: EntityStateModel<ITrailerType>;
    [Resource.VEHICLE_TYPES]: EntityStateModel<IVehicleType>;
    [Resource.ROUTE_TYPES]: InlineEntityStateModel<IRouteType>;
    [Resource.ROUTE_MAP_SYMBOLS]: EntityStateModel<IRouteMapSymbol>;
    [Resource.ROUTES]: EntityStateModel<IRoute>;
    [Resource.SALE_ORDERS]: EntityStateModel<ISaleOrder>;
    [Resource.CLIENT_SALE_ORDERS]: EntityStateModel<IClientSaleOrder>;
    [Resource.STANDING_ORDERS]: EntityStateModel<ISaleOrder>;
    [Resource.STANDING_LOAD_ORDERS]: EntityStateModel<ISaleOrder>;
    [Resource.REPORTS]: EntityStateModel<IReportData>;
    [Resource.SALE_ORDER_TYPES]: PartialEntityStateModel<ISaleOrderType>;
    [Resource.ORDER_PRIORITIES]: PartialEntityStateModel<IOrderPriority>;
    [Resource.ORDER_STATUS_REASONS]: PartialEntityStateModel<IOrderStatusReason>;
    [Resource.DELIVERY_METHODS]: PartialEntityStateModel<IDeliveryMethod>;
    [Resource.ORDER_ITEMS]: PartialEntityStateModel<IOrderItem>;
    [Resource.ORDER_REJECTION_CODES]: EntityStateModel<IOrderRejectionCode>;
    [Resource.INCOMING_INVENTORIES]: EntityStateModel<IIncomingInventory>;
    [Resource.INVENTORY_PRODUCT_ENTRIES]: PartialEntityStateModel<IInventoryProductEntry>;
    [Resource.INVENTORY_REASONS]: PartialEntityStateModel<IInventoryReason>;
    [Resource.INVENTORY_REASON_GROUPS]: PartialEntityStateModel<IInventoryReasonGroup>;
    [Resource.LOAD_ORDERS]: EntityStateModel<ILoadOrder>;
    [Resource.DELIVERY_ITEMS]: PartialEntityStateModel<IDeliveryItem>;
    [Resource.DELIVERY_PACKAGES]: PartialEntityStateModel<IDeliveryPackage>;
    [Resource.PACK_ORDERS]: EntityStateModel<IPackOrder>;
    [Resource.PICK_ORDERS]: EntityStateModel<IPickOrder>;
    [Resource.ORDER_ITEM_REASONS]: PartialEntityStateModel<IOrderItemReason>;
    [Resource.DELIVERY_ORDERS]: PartialEntityStateModel<IDeliveryOrder>;
    [Resource.INVOICES]: EntityStateModel<IInvoice>;
    [Resource.CREDIT_NOTES]: EntityStateModel<ICreditNote>;
    [Resource.HANDHELD_PROFILES]: EntityStateModel<IHandheldProfile>;
    [Resource.HAULIER_ORDERS]: EntityStateModel<IHaulierOrder>;
    [Resource.DRIVER_EOD_REPORTS]: EntityStateModel<IDriverEodReport>;
    [Resource.TEMPERATURE_REPORTS]: EntityStateModel<ITemperatureReport>;
    [Resource.DRIVER_DEPOSIT_REPORTS]: EntityStateModel<IDriverEodReport>;
    [Resource.VEHICLE_INSPECTIONS]: EntityStateModel<IVehicleInspectionReport>;
    [Resource.SITE_VISITS]: EntityStateModel<ISiteVisit>;
    [Resource.PAYMENTS]: EntityStateModel<IPayment>;
    [Resource.EQUIPMENTS]: EntityStateModel<IEquipment>;
    [Resource.SERVICE_ITEMS]: EntityStateModel<IServiceItem>;
    [Resource.SERVICE_ORDERS]: EntityStateModel<IServiceOrder>;
    [Resource.ORGANISATIONS]: EntityStateModel<IOrganisation>;
    [Resource.FUEL_SURCHARGE_GROUPS]: EntityStateModel<IFuelSurchargeGroup>;
    [Resource.STOCK_TAKES]: EntityStateModel<IStockTake>;
    [Resource.PURCHASE_ORDERS]: EntityStateModel<IPurchaseOrder>;
    [Resource.IN_CGST_RATES]: PartialEntityStateModel<ICGSTCategory>;
    [Resource.IN_SGST_RATES]: PartialEntityStateModel<ISGSTCategory>;
    [Resource.IN_IGST_RATES]: PartialEntityStateModel<IIGSTCategory>;
    [Resource.WAREHOUSE_TRANSFERS]: PartialEntityStateModel<IWarehouseTransfer>;
    [Resource.VEHICLE_CHECKLISTS]: EntityStateModel<ISafetyProfile>;
    [Resource.INVENTORY_TRANSACTIONS]: EntityStateModel<IInventoryTransaction>;
    [Resource.CUSTOMER_TRANSACTIONS]: EntityStateModel<ICustomerTransaction>;
    [Resource.DRIVER_ACCESS_CODES]: EntityStateModel<IDriverAccessCodes>;
    [Resource.DB_USER]: EntityStateModel<IDbUser>;
    [Resource.SURVEYS]: EntityStateModel<ISurvey>;
    [Resource.EMPLOYEE_TARGETS]: EntityStateModel<ITarget>;
    [Resource.REASON_CODES]: EntityStateModel<IReasonCode>;
    [Resource.USERS]: EntityStateModel<IUserEntity>;
    [Resource.EOD_SURVEYS]: EntityStateModel<IEodSurvey>;
    [Resource.PRODUCT_IMEI_TRACKS]: EntityStateModel<IProductImeiTrack>;
    [Resource.WORKSHOP]: EntityStateModel<IWorkshop>;
    [Resource.RECIPES]: EntityStateModel<IRecipe>;
    [Resource.TENANTS]: EntityStateModel<ITenant>;
    [Resource.RECIPE_EXECUTION_REPORT]: EntityStateModel<IRecipeExecutionReport>;
    [Resource.STOCK_CHANGE_CONDITION]: EntityStateModel<IStockChangeCondition>;
    [Resource.PURCHASE_INVOICE]: EntityStateModel<IPurchaseInvoice>;
    [Resource.ROUTE_SCHEDULE]: EntityStateModel<ISchedule>;
    [Resource.RESTORES]: EntityStateModel<IRestore>;
    [Resource.BACKUPS]: EntityStateModel<IBackup>;
    [Resource.EXECUTIONS]: EntityStateModel<IExecution>;
    [Resource.ALERTS]: EntityStateModel<IAlert>;
    [Resource.SHORTFALL_REPORT]: EntityStateModel<IShortfallReport>;
    [Resource.PRODUCT_COST_CHANGES]: EntityStateModel<IProductCostChange>;
    [Resource.PRODUCTION_VOUCHERS]: EntityStateModel<IProductionVoucher>;
    [Resource.EOD_INVENTORY_REPORT]: EntityStateModel<IEodInventoryReport>;
    [Resource.SURVEY_REPORT]: EntityStateModel<ISurveyReport>;
    [Resource.APP_VERSION]: EntityStateModel<IAppVersion>;
    [Resource.GCT_RATES]: EntityStateModel<IProductTaxCategory>;
    [Resource.DEFAULT_PRODUCT_PRICE_CHANGES]: EntityStateModel<IDefaultProductPriceChange>;
    [Resource.SITE_VISIT_SKIP_REASONS]: EntityStateModel<ISiteVisitSkipReasons>;
    [Resource.EMAIL_WORKFLOW]: EntityStateModel<IEmailWorkflow>;
    [Resource.ASYNC_REQUESTS]: EntityStateModel<IAsyncRequest>;
    [Resource.BRANCH_PAYMENTS]: EntityStateModel<IBranchPayment>;
    [Resource.BRANCH_TRANSACTIONS]: EntityStateModel<IBranchTransaction>;
    [Resource.ROUTE_TARGETS]: InlineEntityStateModel<ITarget>;
    [Resource.PURCHASE_PAYMENTS]: EntityStateModel<IPurchasePayment>;
    [Resource.PURCHASE_CREDIT_NOTES]: EntityStateModel<IPurchaseCreditNote>;
    [Resource.CATALOGUES]: EntityStateModel<ICatalogueEntity>;
    [Resource.PRESENTATIONS]: EntityStateModel<ICataloguePresentation>;
    [Resource.RESOURCE_ITEMS]: EntityStateModel<ICatalogueResourceItem>;
    [Resource.RESOURCE_GROUPS]: InlineEntityStateModel<ICatalogueResourceGroup>;
    [Resource.SUPPLIER_PAYMENT_TERMS]: InlineEntityStateModel<ISupplierPaymentTerm>;
    [Resource.EMPLOYEE_EXPENSES]: EntityStateModel<EmployeeExpenses>;
    [Resource.EXPENSE_PAYMENT_METHOD]: InlineEntityStateModel<IExpensePaymentMethod>;
    [Resource.EXPENSE_TYPES]: InlineEntityStateModel<IExpenseType>;
    [Resource.BRANDS]: EntityStateModel<IBrand>;
    [Resource.INTEGRATION_MAPPINGS]: InlineEntityStateModel<IIntegrationMapping>;
    [Resource.SUPPLIER_TRANSACTIONS]: EntityStateModel<ISupplierTransaction>;
    [Resource.VEHICLE_SKILL]: EntityStateModel<IVehicleSkill>;
    [Resource.BIN_TYPES]: EntityStateModel<BinType>;
    [Resource.BINS]: EntityStateModel<Bin>;
    [Resource.CUSTOM_PDF_TEMPLATES]: EntityStateModel<ICustomPdfTemplate>;
    [Resource.SUPPLIER_GROUPS]: InlineEntityStateModel<ISupplierGroup>;
    [Resource.BUDGETS]: EntityStateModel<IBudget>;
}

export function getDefaultEntitiesState(): EntitiesStateModel {
    return {
        [Resource.DAILY_ACTIVITY_SUMMARIES]: defaultEntityState(() => new DailyActivitySummary()),
        [Resource.BRANCHES]: defaultEntityState(() => new Branch()),
        [Resource.COUNTRIES]: defaultPartialEntityState(),
        [Resource.WAREHOUSES]: defaultEntityState(() => new Warehouse()),
        [Resource.WAREHOUSE_TYPES]: defaultPartialEntityState(),
        [Resource.COMPANIES]: defaultEntityState(() => new Company()),
        [Resource.CURRENCIES]: defaultPartialEntityState(),
        [Resource.MAJOR_ACCOUNT_CODES]: defaultInlineEntityState(() => new MajorAccountCode(), true),
        [Resource.EMPLOYEES]: defaultEntityState(() => new Employee()),
        [Resource.EMPLOYEE_GROUPS]: defaultInlineEntityState(() => new EmployeeGroup()),
        [Resource.SERVICE_PRIORITY_CODES]: defaultEntityState(() => new ServicePriorityCode()),
        [Resource.CUSTOM_FIELD_GROUPS]: defaultEntityState(() => new CustomFieldGroup()),
        [Resource.PRODUCT_ATTRIBUTE_TYPES]: defaultEntityState(() => new ProductAttributeType()),
        [Resource.CUSTOM_FIELDS]: defaultEntityState(() => new CustomField()),
        [Resource.DEPOSIT_ITEMS]: defaultInlineEntityState(() => new DepositItem(), true),
        [Resource.STATEMENT_CODES]: defaultEntityState(() => new StatementCode()),
        [Resource.STATEMENT_TYPES]: defaultPartialEntityState(),
        [Resource.BILLING_FREQUENCY_TYPES]: defaultPartialEntityState(),
        [Resource.DUNNING_MESSAGE_CODES]: defaultEntityState(() => new DunningMessageCode()),
        [Resource.CREDIT_CLASSES]: defaultEntityState(() => new CreditClass()),
        [Resource.CREDIT_TERMS]: defaultInlineEntityState(() => new CreditTerm(), true),
        [Resource.DELIVERY_POINTS]: defaultEntityState(() => new DeliveryPoint()),
        [Resource.DYNAMIC_HEADER_CODES]: defaultPartialEntityState(),
        [Resource.STOP_TYPES]: defaultEntityState(() => new StopType()),
        [Resource.CUSTOMERS]: defaultEntityState(() => new Customer()),
        [Resource.PAYMENT_TYPES]: defaultInlineEntityState(() => new PaymentType(), true),
        [Resource.CUSTOMER_STATUSES]: defaultPartialEntityState(),
        [Resource.DELIVERY_POINT_MESSAGE_TYPES]: defaultPartialEntityState(),
        [Resource.CUSTOMER_STATUS_REASONS]: defaultPartialEntityState(),
        [Resource.CUSTOMER_STATUS_REASON_GROUPS]: defaultPartialEntityState(),
        [Resource.CUSTOMER_GROUPS]: defaultInlineEntityState(() => new CustomerGroup(), true),
        [Resource.PROMOTIONS]: defaultEntityState(() => new Promotion()),
        [Resource.PRICE_LISTS]: defaultEntityState(() => new PriceList()),
        [Resource.PRICE_LIST_TYPES]: defaultPartialEntityState(),
        [Resource.DURATION_PRICE_LISTS]: defaultPartialEntityState(),
        [Resource.LIST_PRODUCT_PRICES]: defaultPartialEntityState(),
        [Resource.SUPPLIERS]: defaultEntityState(() => new Supplier()),
        [Resource.CUSTOMER_SMS_TEXTS]: defaultInlineEntityState(() => new CustomerSmsText(), true),
        [Resource.PRODUCT_CLASSES]: defaultInlineEntityState(() => new ProductClass(), true),
        [Resource.PRODUCT_GROUPS]: defaultInlineEntityState(() => new ProductGroup(), true),
        [Resource.UNIT_OF_MEASURES]: defaultEntityState(() => new UnitOfMeasure()),
        [Resource.PACKAGINGS]: defaultInlineEntityState(() => new Packaging(), true),
        [Resource.PRODUCT_PACKAGINGS]: defaultPartialEntityState(),
        [Resource.PRODUCTS]: defaultEntityState(() => new Product()),
        [Resource.PRODUCT_LISTS]: defaultEntityState(() => new ProductList()),
        [Resource.UK_VAT_RATES]: defaultEntityState(() => new ProductTaxCategory()),
        [Resource.ROI_VAT_RATES]: defaultEntityState(() => new ProductTaxCategory()),
        [Resource.DURATION_PRODUCT_PRICE_INFOS]: defaultPartialEntityState(),
        [Resource.DELIVERY_PACKAGE_TYPES]: defaultInlineEntityState(() => new DeliveryPackageType(), true),
        [Resource.VEHICLES]: defaultEntityState(() => new Vehicle()),
        [Resource.VEHICLE_TYPES]: defaultEntityState(() => new VehicleType()),
        [Resource.TRAILERS]: defaultEntityState(() => new Trailer()),
        [Resource.TRAILER_TYPES]: defaultEntityState(() => new TrailerType()),
        [Resource.VEHICLE_UNLOADS]: defaultEntityState(() => new VehicleUnload(), true),
        [Resource.ROUTE_TYPES]: defaultInlineEntityState(() => new RouteType(), true),
        [Resource.ROUTE_MAP_SYMBOLS]: defaultEntityState(() => new RouteMapSymbol()),
        [Resource.ROUTES]: defaultEntityState(() => new Route()),
        [Resource.SALE_ORDERS]: defaultEntityState(() => new SaleOrder(), true),
        [Resource.CLIENT_SALE_ORDERS]: defaultEntityState(() => new ClientSaleOrder(), true),
        [Resource.STANDING_ORDERS]: defaultEntityStateWithDefaultFilters(
            () => new StandingOrder(),
            true,
            'id',
            defaultFilterParams.STANDING_SALE_ORDERS
        ),
        [Resource.STANDING_LOAD_ORDERS]: defaultEntityStateWithDefaultFilters(
            () => new StandingOrder(),
            true,
            'id',
            defaultFilterParams.STANDING_LOAD_ORDERS
        ),
        [Resource.SALE_ORDER_TYPES]: defaultPartialEntityState(),
        [Resource.DELIVERY_METHODS]: defaultPartialEntityState(),
        [Resource.ORDER_PRIORITIES]: defaultPartialEntityState(),
        [Resource.ORDER_STATUS_REASONS]: defaultPartialEntityState(),
        [Resource.ORDER_ITEMS]: defaultPartialEntityState(),
        [Resource.REPORTS]: defaultEntityState(() => new ReportData()),
        [Resource.ORDER_REJECTION_CODES]: defaultEntityState(() => new OrderRejectionCode()),
        [Resource.INCOMING_INVENTORIES]: defaultEntityState(() => new IncomingInventory(), true),
        [Resource.INVENTORY_PRODUCT_ENTRIES]: defaultPartialEntityState(),
        [Resource.INVENTORY_REASONS]: defaultPartialEntityState(),
        [Resource.INVENTORY_REASON_GROUPS]: defaultPartialEntityState(),
        [Resource.LOAD_ORDERS]: defaultEntityState(() => new LoadOrder(), true),
        [Resource.DELIVERY_ITEMS]: defaultPartialEntityState(),
        [Resource.DELIVERY_PACKAGES]: defaultPartialEntityState(),
        [Resource.PACK_ORDERS]: defaultEntityState(() => new PackOrder(), true),
        [Resource.PICK_ORDERS]: defaultEntityState(() => new PickOrder(), true),
        [Resource.ORDER_ITEM_REASONS]: defaultPartialEntityState(),
        [Resource.DELIVERY_ORDERS]: defaultEntityState(() => new DeliveryOrder(), true),
        [Resource.INVOICES]: defaultEntityState(() => new Invoice(), true),
        [Resource.CREDIT_NOTES]: defaultEntityState(() => new CreditNote(), true),
        [Resource.HANDHELD_PROFILES]: defaultEntityState(() => new HandheldProfile()),
        [Resource.HAULIER_ORDERS]: defaultEntityState(() => new HaulierOrder()),
        [Resource.DRIVER_EOD_REPORTS]: defaultEntityState(() => new DriverEodReport(), true),
        [Resource.TEMPERATURE_REPORTS]: defaultEntityState(() => new TemperatureReport(), true),
        [Resource.DRIVER_DEPOSIT_REPORTS]: defaultEntityState(() => new DriverEodReport(), true),
        [Resource.VEHICLE_INSPECTIONS]: defaultEntityState(() => new VehicleInspectionReport(), true),
        [Resource.SITE_VISITS]: defaultEntityState(() => new SiteVisit(), true, 'serviceDate,route'),
        [Resource.PAYMENTS]: defaultEntityState(() => new Payment(), true),
        [Resource.EQUIPMENTS]: defaultEntityState(() => new Equipment(), true),
        [Resource.SERVICE_ITEMS]: defaultEntityState(() => new ServiceItem(), true),
        [Resource.SERVICE_ORDERS]: defaultEntityState(() => new ServiceOrder(), true),
        [Resource.ORGANISATIONS]: defaultEntityState(() => new Organisation()),
        [Resource.FUEL_SURCHARGE_GROUPS]: defaultEntityState(() => new FuelSurchargeGroup()),
        [Resource.STOCK_TAKES]: defaultEntityState(() => new StockTake(), true),
        [Resource.PURCHASE_ORDERS]: defaultEntityState(() => new PurchaseOrder(), true),
        [Resource.WAREHOUSE_TRANSFERS]: defaultEntityState(() => new WarehouseTransfer(), true),
        [Resource.INVENTORY_TRANSACTIONS]: defaultEntityState(() => new InventoryTransaction(), true),
        [Resource.IN_CGST_RATES]: defaultPartialEntityState(),
        [Resource.IN_SGST_RATES]: defaultPartialEntityState(),
        [Resource.IN_IGST_RATES]: defaultPartialEntityState(),
        [Resource.VEHICLE_CHECKLISTS]: defaultEntityState(() => new SafetyProfile(), true),
        [Resource.CUSTOMER_TRANSACTIONS]: defaultEntityState(() => new CustomerTransaction(), true, 'transactionDate,id'),
        [Resource.DRIVER_ACCESS_CODES]: defaultEntityState(() => new DriverAccessCodes(), true),
        [Resource.SURVEYS]: defaultEntityState(() => new Survey(), true),
        [Resource.EMPLOYEE_TARGETS]: defaultEntityState(() => new Target(), true),
        [Resource.REASON_CODES]: defaultEntityState(() => new ReasonCode(), true),
        [Resource.TENANTS]: defaultEntityState(() => new Tenant(), false),
        [Resource.USERS]: defaultEntityState(() => new UserEntity(), true),
        [Resource.EOD_SURVEYS]: defaultEntityState(() => new EodSurvey(), true),
        [Resource.PRODUCT_IMEI_TRACKS]: defaultEntityState(() => new ProductImeiTrack(), true),
        [Resource.WORKSHOP]: defaultEntityState(() => new Workshop()),
        [Resource.ROLES]: defaultEntityState(() => new Role()),
        [Resource.PROFILES]: defaultEntityState(() => new Profile()),
        [Resource.RECIPES]: defaultEntityState(() => new Recipe(), true),
        [Resource.RECIPE_EXECUTION_REPORT]: defaultEntityState(() => new RecipeExecutionReport(), true, 'executionDate'),
        [Resource.STOCK_CHANGE_CONDITION]: defaultEntityState(() => new StockChangeCondition()),
        [Resource.PURCHASE_INVOICE]: defaultEntityState(() => new PurchaseInvoice(), true, 'id'),
        [Resource.ROUTE_SCHEDULE]: defaultEntityState(() => new Schedule()),
        [Resource.BACKUPS]: defaultEntityState(() => new Backup(), true),
        [Resource.RESTORES]: defaultEntityState(() => new Restore(), true),
        [Resource.EXECUTIONS]: defaultEntityState(() => new Execution()),
        [Resource.ALERTS]: defaultEntityState(() => new Alert()),
        [Resource.SHORTFALL_REPORT]: defaultEntityState(() => new ShortfallReport()),
        [Resource.PRODUCT_COST_CHANGES]: defaultEntityState(() => new ProductCostChange(), true, 'id'),
        [Resource.PRODUCTION_VOUCHERS]: defaultEntityState(() => new ProductionVoucher()),
        [Resource.EOD_INVENTORY_REPORT]: defaultEntityState(() => new EodInventoryReport(), true, 'id'),
        [Resource.SURVEY_REPORT]: defaultEntityState(() => new SurveyReport(), true, 'id'),
        [Resource.APP_VERSION]: defaultEntityState(() => new AppVersion()),
        [Resource.GCT_RATES]: defaultEntityState(() => new ProductTaxCategory()),
        [Resource.DEFAULT_PRODUCT_PRICE_CHANGES]: defaultEntityState(() => new DefaultProductPriceChange()),
        [Resource.SITE_VISIT_SKIP_REASONS]: defaultEntityState(() => new SiteVisitSkipReasons()),
        [Resource.EMAIL_WORKFLOW]: defaultEntityState(() => new EmailWorkflow()),
        [Resource.ASYNC_REQUESTS]: defaultEntityState(() => new AsyncRequest()),
        [Resource.BRANCH_PAYMENTS]: defaultEntityState(() => new BranchPayment(), true, 'id'),
        [Resource.BRANCH_TRANSACTIONS]: defaultEntityState(() => new BranchTransaction(), true, 'id'),
        [Resource.ROUTE_TARGETS]: defaultInlineEntityState(() => new Target(), true),
        [Resource.PURCHASE_CREDIT_NOTES]: defaultEntityState(() => new PurchaseCreditNote(), true),
        [Resource.PURCHASE_PAYMENTS]: defaultEntityState(() => new PurchasePayment(), true, 'id'),
        [Resource.CATALOGUES]: defaultEntityState(() => new CatalogueEntity()),
        [Resource.PRESENTATIONS]: defaultEntityState(() => new CataloguePresentation()),
        [Resource.RESOURCE_ITEMS]: defaultEntityState(() => new CatalogueResourceItem()),
        [Resource.RESOURCE_GROUPS]: defaultInlineEntityState(() => new CatalogueResourceGroup()),
        [Resource.SUPPLIER_PAYMENT_TERMS]: defaultInlineEntityState(() => new SupplierPaymentTerm(), true),
        [Resource.EMPLOYEE_EXPENSES]: defaultEntityState(() => new EmployeeExpenses()),
        [Resource.EXPENSE_PAYMENT_METHOD]: defaultInlineEntityState(() => new ExpensePaymentMethod()),
        [Resource.EXPENSE_TYPES]: defaultInlineEntityState(() => new ExpenseType()),
        [Resource.BRANDS]: defaultEntityState(() => new Brand()),
        [Resource.INTEGRATION_MAPPINGS]: defaultInlineEntityState(() => new IntegrationMapping(), true),
        [Resource.SUPPLIER_TRANSACTIONS]: defaultEntityState(() => new SupplierTransaction(), true),
        [Resource.DB_USER]: defaultEntityState(() => new DbUser()),
        [Resource.VEHICLE_SKILL]: defaultEntityState(() => new VehicleSkill()),
        [Resource.BIN_TYPES]: defaultEntityState(() => new BinType(), true, 'id'),
        [Resource.BINS]: defaultEntityState(() => new Bin(), true, 'id'),
        [Resource.CUSTOM_PDF_TEMPLATES]: defaultEntityState(() => new CustomPdfTemplate()),
        [Resource.SUPPLIER_GROUPS]: defaultInlineEntityState(() => new SupplierGroup(), true),
        [Resource.BUDGETS]: defaultEntityState(() => new Budget(), true, 'id')
    };
}

@State<EntitiesStateModel>({
    name: 'entities',
    defaults: getDefaultEntitiesState()
})
@Injectable({
    providedIn: 'root'
})
export class EntitiesState {
    retainPreviousScreenFilters = true;

    constructor(
        private _entityActionHandler: EntityActionHandler,
        private _alertService: AlertService,
        private _settingMetaDataService: SettingMetaDataService,
        private _incomingInventoryService: IncomingInventoryService,
        private _saleOrderService: SaleOrderService,
        private _serviceOrderService: ServiceOrderService,
        private _purchaseOrderService: PurchaseOrderService,
        private _pickOrderService: PickOrderService,
        private _packOrderService: PackOrderService,
        private _loadOrderService: LoadOrderService,
        private _companyService: CompanyService,
        private _invoiceService: InvoiceService,
        private _productService: ProductService,
        private _purchaseInvoiceService: PurchaseInvoiceService,
        protected _principalService?: Principal,
        private _scheduleService?: ScheduleService,
        private _superUserService?: SuperUserService
    ) {
        this._entityActionHandler.init();
        this._principalService
            .getAuthenticationState()
            .pipe()
            .subscribe((user) => {
                if (user != null && !_superUserService.getIsSuperUser()) {
                    this._companyService.getSettingValue(RETAIN_PREVIOUS_SCREEN_FILTERS).then((res) => {
                        if (res != null && res.retainPreviousScreenFilters != null) {
                            this.retainPreviousScreenFilters = res.retainPreviousScreenFilters === 'true';
                        }
                    });
                }
            });
    }

    @Action(GetEntityList)
    async getEntityList(ctx: StateContext<EntitiesStateModel>, action: IGetEntityList): Promise<any[]> {
        const prevState = ctx.getState();
        let viewParams = prevState[action.resource.name]['listView']['viewParams'];
        if (DEBUG_PAGE_PARAMS) {
            console.log(`Old view are params: ${JSON.stringify(viewParams)}`);
            console.log(`New view are params: ${JSON.stringify(action.viewParams)}`);
        }

        console.log(action.viewParams);
        if (action.viewParams) {
            if (_.isEqual(viewParams, action.viewParams)) {
                if (DEBUG_PAGE_PARAMS) {
                    console.log(`No change in view params`);
                }
                return;
            }
            viewParams = action.viewParams;
        }

        let api: (request: any) => Promise<HttpResponse<any[]>>;
        if (this._principalService && this._principalService.isUserCustomer()) {
            api = viewParams.filter ? action.resource.api.customerSearchHttp : action.resource.api.customerQueryHtpp;
        } else {
            api = viewParams.filter ? action.resource.api.searchHttp : action.resource.api.queryHttp;
        }
        return this._fetchEntityList(ctx, action.resource, viewParams, api);
    }

    @Action(ChangeEntityListViewParams)
    changeBranchListViewParams(ctx: StateContext<EntitiesStateModel>, action: IChangeEntityListViewParams): void {
        this.getEntityList(ctx, action as IGetEntityList);
    }

    @Action(GetNewEntity)
    async newEntity(ctx: StateContext<EntitiesStateModel>, action: IGetNewEntity): Promise<any> {
        const entity = action.resource.newInstance();
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.currentEntity = entity;
                entityView.statusSaving = false;
                entityView.statusDeleting = false;
                entityView.selectedTabIndex = 0;
            })
        );
        return entity;
    }

    @Action(GetNewEntityWithSettings)
    async newEntityWithSettings(ctx: StateContext<EntitiesStateModel>, action: IGetNewEntityWithSettings): Promise<any> {
        const entity = action.resource.newInstance();
        const metaData = await this._settingMetaDataService.getResourceSettingMetaData(action.settingResourceName);
        entity['readSettings'] = metaData;
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.currentEntity = entity;
                entityView.statusSaving = false;
                entityView.statusDeleting = false;
                entityView.selectedTabIndex = 0;
            })
        );
        return entity;
    }

    @Action(UpdateEntity)
    async updateEntity(ctx: StateContext<EntitiesStateModel>, action: IUpdateEntity): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const entity = await action.resource.api.update(action.entity);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            if (action.entity?.approveOnCreateOrUpdate === true) {
                this._alertService.showSuccess(`${action.resource.name}.messages.entityUpdatedAndApproved.message`, null, {
                    discriminator: entity.discriminator
                });
            } else {
                this._alertService.showSuccess(`${action.resource.name}.messages.entityUpdated.message`, null, {discriminator: entity.discriminator});
            }
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    private resetUpdateState(ctx: StateContext<EntitiesStateModel>, action: IEntityAction): void {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = false;
                entityView.statusDeleting = false;
            })
        );
    }

    @Action(UpdateCreditNoteAllocated)
    async updateCreditNoteAllocated(ctx: StateContext<EntitiesStateModel>, action: UpdateCreditNoteAllocated): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityList = draft[action.resource.name]['listView'].entityList as any[];
                entityList.forEach((data: ICreditNote, index: number) => {
                    if (data.id === action.entity.id) {
                        entityList[index].allocatedAmount = action.entity.allocatedAmount;
                    }
                });
            })
        );
    }

    @Action(UpdatePaymentAllocated)
    async updatePaymentAllocated(ctx: StateContext<EntitiesStateModel>, action: UpdatePaymentAllocated): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityList = draft[action.resource.name]['listView'].entityList as any[];
                entityList.forEach((data: IPayment, index: number) => {
                    if (data.id === action.entity.id) {
                        entityList[index].allocatedAmount = action.entity.allocatedAmount;
                    }
                });
            })
        );
    }

    @Action(UpdateProduct)
    async updateProduct(ctx: StateContext<EntitiesStateModel>, action: IUpdateProduct): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const uploadPhotos = action.uploadPhotos as IImage[];
            const deletePhotos = action.deletedPhotos as IImage[];
            let entity = await action.resource.api.update(action.entity);
            if (uploadPhotos && uploadPhotos.length > 0) {
                for (const file of uploadPhotos) {
                    await this._productService.uploadFile(file.name, file.multipartFile, entity.id, file.attributes);
                }
            }
            if (deletePhotos && deletePhotos.length > 0) {
                for (const file of deletePhotos) {
                    await this._productService.deleteFile(entity.id, file.id);
                }
            }
            entity = await action.resource.api.find(entity.id);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            this._alertService.showSuccess(`${action.resource.name}.messages.entityUpdated.message`, null, {discriminator: entity.discriminator});
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(CreateProduct)
    async createProduct(ctx: StateContext<EntitiesStateModel>, action: ICreateProduct): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const uploadPhotos = action.uploadPhotos as IImage[];
            let entity = await action.resource.api.create(action.entity);
            if (uploadPhotos && uploadPhotos.length > 0) {
                for (const file of uploadPhotos) {
                    await this._productService.uploadFile(file.name, file.multipartFile, entity.id, file.attributes);
                }
            }
            entity = await action.resource.api.find(entity.id);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            this._alertService.showSuccess(`${action.resource.name}.messages.entityAdded.message`, null, {discriminator: entity.discriminator});
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(UpdateSaleOrder)
    async updateSaleOrder(ctx: StateContext<EntitiesStateModel>, action: IUpdateSaleOrder): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const uploadDocuments = action.uploadDocuments as IMposfile[];
            const deletedDocuments = action.deletedDocuments as IMposfile[];
            let entity = await action.resource.api.update(action.entity);
            if (uploadDocuments && uploadDocuments.length > 0) {
                for (const file of uploadDocuments) {
                    await this._saleOrderService.uploadFile(file.label, file.multipartFile, entity.id);
                }
            }

            if (deletedDocuments && deletedDocuments.length > 0) {
                for (const file of deletedDocuments) {
                    await this._saleOrderService.deleteFile(entity.id, file.id);
                }
            }

            entity = await action.resource.api.find(entity.id);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );

            if (action.entity?.approveOnCreateOrUpdate === true) {
                this._alertService.showSuccess(`${action.resource.name}.messages.entityUpdatedAndApproved.message`, null, {
                    discriminator: entity.discriminator
                });
            } else {
                this._alertService.showSuccess(`${action.resource.name}.messages.entityUpdated.message`, null, {discriminator: entity.discriminator});
            }
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(CreateSaleOrder)
    async createSaleOrder(ctx: StateContext<EntitiesStateModel>, action: ICreateSaleOrder): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const uploadDocuments = action.uploadDocuments as IMposfile[];
            let entity = await action.resource.api.create(action.entity);
            console.log('ENTITY IN SALE ORDER', action.entity);
            if (uploadDocuments.length > 0) {
                for (const file of uploadDocuments) {
                    await this._saleOrderService.uploadFile(file.label, file.multipartFile, entity.id);
                }
            }
            entity = await action.resource.api.find(entity.id);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );

            if (action.entity?.approveOnCreateOrUpdate === true) {
                this._alertService.showSuccess(`${action.resource.name}.messages.entityAddedAndApproved.message`, null, {
                    discriminator: entity.discriminator
                });
            } else {
                this._alertService.showSuccess(`${action.resource.name}.messages.entityAdded.message`, null, {discriminator: entity.discriminator});
            }
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(DuplicateEntity)
    async duplicateEntity(ctx: StateContext<EntitiesStateModel>, action: IDuplicateEntity): Promise<any> {
        const entity = await action.resource.api.find(action.id);
        entity.id = null;
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.currentEntity = entity;
            })
        );

        return entity;
    }

    @Action(CreateEntity)
    async createEntity(ctx: StateContext<EntitiesStateModel>, action: ICreateEntity): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const entity = await action.resource.api.create(action.entity);
            console.log('ENTITY', entity);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            if (action.entity?.approveOnCreateOrUpdate === true) {
                this._alertService.showSuccess(`${action.resource.name}.messages.entityAddedAndApproved.message`, null, {
                    discriminator: entity.discriminator
                });
            } else {
                this._alertService.showSuccess(`${action.resource.name}.messages.entityAdded.message`, null, {discriminator: entity.discriminator});
            }
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(GetEntity)
    async getEntity(ctx: StateContext<EntitiesStateModel>, action: IGetEntity): Promise<any> {
        let entity;
        if (this._principalService.isUserCustomer()) {
            entity = await action.resource.api.customerFind(action.id);
        } else {
            entity = await action.resource.api.find(action.id);
        }
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                if (entityView.currentEntity && entityView.currentEntity.id !== entity.id) {
                    entityView.selectedTabIndex = 0;
                }
                entityView.currentEntity = entity;
                entityView.statusSaving = false;
                entityView.statusDeleting = false;
            })
        );
    }

    @Action(DeleteEntity)
    async deleteEntity(ctx: StateContext<EntitiesStateModel>, action: IDeleteEntity): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusDeleting = true;
            })
        );
        try {
            const entity = await action.resource.api.find(action.id);
            const response = await action.resource.api.deleteHttp(action.id);
            if (response.status === 200) {
                console.log('Deleted successfully');
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        const entityView = draft[action.resource.name]['entityView'];
                        entityView.statusDeleting = false;
                        const selectedIds = draft[action.resource.name]['listView'].selectedIds;
                        draft[action.resource.name]['listView'].selectedIds = selectedIds.filter((id) => id !== action.id);

                        const state = ctx.getState();
                        for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                            draft[action.resource.name].partialEntity[listName].loaded = false;
                        }
                    })
                );
                this._alertService.showSuccess(`${action.resource.name}.messages.entityDeleted.message`, null, {discriminator: entity.discriminator});
            }
            return true;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(CreateInlineEntity)
    async createInlineEntity(ctx: StateContext<EntitiesStateModel>, action: ICreateInlineEntity): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['listView']['statusSaving'] = true;
            })
        );
        try {
            const entity = await action.resource.api.create(action.entity);
            this._alertService.showSuccess(`${action.resource.name}.messages.entityAdded.message`, null, {discriminator: entity.discriminator});
            this.resetInlineUpdateState(ctx, action);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    for (const listName of Object.keys(draft[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            ctx.dispatch(new GetEntityList(action.resource));
        } catch (err) {
            this.resetInlineUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(UpdateInlineEntity)
    async updateInlineEntity(ctx: StateContext<EntitiesStateModel>, action: IUpdateInlineEntity): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['listView']['statusSaving'] = true;
            })
        );
        try {
            const entity = await action.resource.api.update(action.entity);
            this._alertService.showSuccess(`${action.resource.name}.messages.entityUpdated.message`, null, {discriminator: entity.discriminator});
            this.resetInlineUpdateState(ctx, action);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    for (const listName of Object.keys(draft[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            ctx.dispatch(new GetEntityList(action.resource));
        } catch (err) {
            this.resetInlineUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(StartInlineEntityAdd)
    async startAddInlineEntity(ctx: StateContext<EntitiesStateModel>, action: IStartInlineEntityAdd): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['listView']['statusUserInput'] = true;
                draft[action.resource.name]['listView']['statusAdding'] = true;
            })
        );
        // Move to last page as well
    }

    private resetInlineUpdateState(ctx: StateContext<EntitiesStateModel>, action: IUpdateEntity): void {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['listView']['statusSaving'] = false;
                draft[action.resource.name]['listView']['statusAdding'] = false;
                draft[action.resource.name]['listView']['statusUserInput'] = false;
            })
        );
    }

    @Action(StartInlineEntityUpdate)
    async startInlineUpdateEntity(ctx: StateContext<EntitiesStateModel>, action: IStartInlineEntityUpdate): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['listView']['statusUserInput'] = true;
            })
        );
    }

    @Action(CancelInlineEntityUpdate)
    async cancelInlineUpdateEntity(ctx: StateContext<EntitiesStateModel>, action: ICancelInlineEntityUpdate): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['listView']['statusUserInput'] = false;
                draft[action.resource.name]['listView']['statusAdding'] = false;
            })
        );
    }

    @Action(SelectEntity)
    async selectEntity(ctx: StateContext<EntitiesStateModel>, action: ISelectEntity): Promise<any> {
        const prevState = ctx.getState();
        const oldSelectedIds: number[] = prevState[action.resource.name]['listView'].selectedIds;
        const oldSelectedIdsCopy = oldSelectedIds.slice();
        let newEntityIds = action.entityIds.slice();
        newEntityIds = newEntityIds.filter((id) => !oldSelectedIds.includes(id));

        if (newEntityIds.length > 0) {
            oldSelectedIdsCopy.push(...newEntityIds);
            ctx.setState({
                ...prevState,
                [action.resource.name]: {
                    ...prevState[action.resource.name],
                    listView: {
                        ...prevState[action.resource.name]['listView'],
                        selectedIds: oldSelectedIdsCopy
                    }
                }
            });
        }
    }

    @Action(SelectAllCurrentPageEntities)
    async selectAllCurrentPageEntities(ctx: StateContext<EntitiesStateModel>, action: ISelectAllCurrentPageEntities): Promise<any> {
        const prevState = ctx.getState();
        const entityList: any[] = prevState[action.resource.name]['listView'].entityList;
        const prevSelectedIds: any[] = prevState[action.resource.name]['listView'].selectedIds;
        const toSelectEntityIds = entityList.map((entity) => entity['id']).filter((id) => !prevSelectedIds.includes(id));
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['listView'].selectedIds.push(...toSelectEntityIds);
            })
        );
    }

    @Action(UnselectAllCurrentPageEntities)
    async unselectAllCurrentPageEntities(ctx: StateContext<EntitiesStateModel>, action: IUnselectAllCurrentPageEntities): Promise<any> {
        const prevState = ctx.getState();
        const entityList: any[] = prevState[action.resource.name]['listView'].entityList;
        const toUnselectEntityIds = entityList.map((entity) => entity['id']);
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['listView'].selectedIds = draft[action.resource.name]['listView'].selectedIds.filter(
                    (id) => !toUnselectEntityIds.includes(id)
                );
            })
        );
    }

    @Action(UnselectEntity)
    async unselectEntity(ctx: StateContext<EntitiesStateModel>, action: IUnselectEntity): Promise<any> {
        const prevState = ctx.getState();
        const selectedIds: number[] = prevState[action.resource.name]['listView'].selectedIds;
        const newSelectedIds = selectedIds.filter((x) => !action.entityIds.includes(x));

        ctx.setState({
            ...prevState,
            [action.resource.name]: {
                ...prevState[action.resource.name],
                listView: {
                    ...prevState[action.resource.name]['listView'],
                    selectedIds: newSelectedIds
                }
            }
        });
    }

    @Action(SelectEntityTabIndex)
    async selectEntityTabIndex(ctx: StateContext<EntitiesStateModel>, action: ISelectEntityTabIndex): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['entityView'].selectedTabIndex = action.tabIndex;
            })
        );
    }

    @Action(GetNamedEntityList)
    async getPartialEntityList(ctx: StateContext<EntitiesStateModel>, action: AbstractGetNamedEntityList): Promise<any[]> {
        const currentState = ctx.getState();
        if (action.forceReload || currentState[action.resource.name].partialEntity[action.name].loaded === false) {
            const namedList = await action.resource.api.queryPartial({
                projection: action.name
            });
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    draft[action.resource.name].partialEntity[action.name] = {
                        list: namedList,
                        loaded: true
                    };
                })
            );
            return namedList;
        }
    }

    @Action(ReceiveInventory)
    async receiveInventory(ctx: StateContext<EntitiesStateModel>, action: ReceiveInventory): Promise<void> {
        const response = await this._incomingInventoryService.receiveInventory(action.id, action.adjDate, action.adjTime);
        if (response.status === 200) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity['incomingInventoryStatus'] = 'RECEIVED';
                })
            );
        }
    }

    @Action(ConfirmSaleOrder)
    async confirmSaleOrder(ctx: StateContext<EntitiesStateModel>, action: ConfirmSaleOrder): Promise<void> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const response = await this._saleOrderService.confirmSaleOrder(action.id);
            if (response.status === 200) {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        const entityView = draft[action.resource.name]['entityView'];
                        entityView.currentEntity['saleOrderStatus'] = 'CONFIRMED_APPROVED';
                        entityView.statusSaving = false;
                    })
                );
            }
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(ConfirmServiceOrder)
    async confirmServiceOrder(ctx: StateContext<EntitiesStateModel>, action: ConfirmServiceOrder): Promise<void> {
        const response = await this._serviceOrderService.confirmServiceOrder(action.id);
        if (response.status === 200) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity['serviceOrderStatus'] = 'CONFIRMED_APPROVED';
                })
            );
        }
    }

    @Action(CancelSaleOrder)
    async cancelSaleOrder(ctx: StateContext<EntitiesStateModel>, action: CancelSaleOrder): Promise<void> {
        const response = await this._saleOrderService.cancelSaleOrder(action.id, action.date, action.time);
        if (response.status === 200) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityList: any[] = draft[action.resource.name]['listView']['entityList'];
                    for (const entity of entityList) {
                        if (entity.id === action.id) {
                            entity.saleOrderStatus = SaleOrderStatus.CANCELLED;
                        }
                    }
                })
            );
        }
    }

    @Action(CancelServiceOrder)
    async cancelServiceOrder(ctx: StateContext<EntitiesStateModel>, action: CancelServiceOrder): Promise<void> {
        const response = await this._serviceOrderService.cancelServiceOrder(action.id);
        if (response.status === 200) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityList: any[] = draft[action.resource.name]['listView']['entityList'];
                    for (const entity of entityList) {
                        if (entity.id === action.id) {
                            entity.serviceOrderStatus = 'CANCELLED';
                        }
                    }
                })
            );
        }
    }

    @Action(ValidatePickOrder)
    async validatePickOrder(ctx: StateContext<EntitiesStateModel>, action: ValidatePickOrder): Promise<void> {
        const response = await this._pickOrderService.validatePickOrder(action.request);
        if (response.status === 200) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity['pickOrderStatus'] = 'PICKED';
                })
            );
        }
    }

    @Action(CompletePackOrder)
    async completePackOrder(ctx: StateContext<EntitiesStateModel>, action: CompletePackOrder): Promise<void> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const response = await this._packOrderService.completePackOrder(action.request);
            if (response.status === 200) {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        const entityView = draft[action.resource.name]['entityView'];
                        entityView.currentEntity['packOrderStatus'] = 'PACKED';
                        entityView.statusSaving = false;
                    })
                );
            }
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(ProcessLoadOrder)
    async processLoadOrder(ctx: StateContext<EntitiesStateModel>, action: ProcessLoadOrder): Promise<void> {
        const response = await this._loadOrderService.process(action.request);
        if (response.status === 200) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name].entityView;
                    entityView.currentEntity.processed = true;
                })
            );
        }
    }

    @Action(CancelLoadOrder)
    async cancelLoadOrder(ctx: StateContext<EntitiesStateModel>, action: CancelLoadOrder): Promise<void> {
        const response = await this._loadOrderService.cancel(action.id, action.cancelDate.toString(), action.cancelTime);
        if (response.status === 200) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name].entityView;
                    entityView.currentEntity.processed = true;
                })
            );
        }
    }

    @Action(GetCompanyEntityOnLoadWithoutId)
    async getCompanyEntityOnLoadWithoutId(ctx: StateContext<EntitiesStateModel>, action: IGetCompanyEntityOnLoadWithoutId): Promise<any> {
        let entity = action.resource.newInstance();
        const entityObj = await this._companyService.getCompany();
        entity = entityObj;
        // if (entity.readSettings === undefined || entity.readSettings.length === 0) {
        //     const metaData = await this._settingMetaDataService.getSettingMetaData(action.settingResourceName);
        //     entity['readSettings'] = metaData;
        // }
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.currentEntity = entity;
                entityView.statusSaving = false;
                entityView.statusDeleting = false;
                entityView.selectedTabIndex = 0;
            })
        );
        return entity;
    }

    @Action(StartPickOrder)
    async startPickOrder(ctx: StateContext<EntitiesStateModel>, action: StartPickOrder): Promise<void> {
        const response = await this._pickOrderService.startPickOrder(action.id, action.request);
        if (response.status === 200) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity['pickOrderStatus'] = 'IN_PROGRESS';
                })
            );
        }
    }

    @Action(MarkPickOrderAsPending)
    async markPickOrderAsPending(ctx: StateContext<EntitiesStateModel>, action: MarkPickOrderAsPending): Promise<void> {
        const response = await this._pickOrderService.bulkMarkAsPending([
            {
                id: action.id,
                notes: action.notes,
                markAsPendingDate: action.markAsPendingDate.toString(),
                markAsPendingTime: action.markAsPendingTime,
                orderItems: action.orderItems
            }
        ]);
        if (
            response.status === 200 &&
            response.body != null &&
            response.body.completed != null &&
            response.body.completed.length == 1 &&
            response.body.completed[0] === action.id
        ) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity['pickOrderStatus'] = 'PENDING';
                })
            );
        }
    }

    @Action(StartPackOrder)
    async startPackOrder(ctx: StateContext<EntitiesStateModel>, action: StartPackOrder): Promise<void> {
        const response = await this._packOrderService.startPackOrder(action.id);
        if (response.status === 200) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity['packOrderStatus'] = 'IN_PROGRESS';
                    entityView.statusSaving = false;
                })
            );
        }
    }

    @Action(MarkInvoiceAsReady)
    async markInvoiceAsReady(ctx: StateContext<EntitiesStateModel>, action: MarkInvoiceAsReady): Promise<void> {
        const response = await this._invoiceService.markInvoiceAsReady(action.id);
        if (response.status === 200) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity['invoiceStatus'] = 'READY';
                })
            );
        }
    }

    @Action(InvoiceCustomer)
    async invoiceCustomer(ctx: StateContext<EntitiesStateModel>, action: InvoiceCustomer): Promise<void> {
        const response = await this._invoiceService.invoiceCustomer(action.id);
        if (response.status === 200) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity['invoiceStatus'] = 'INVOICED';
                })
            );
        }
    }

    @Action(ExportEntities)
    async exportEntities(ctx: StateContext<EntitiesStateModel>, action: IExportEntities): Promise<any> {
        const response = await action.resource.api.export(action.ids, {
            ...action.params
        });
        if (response.status === 200) {
            const type = response.headers.get('Content-Type');
            saveAs(new Blob([response.body], {type}), action.filename);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityList: any[] = draft[action.resource.name]['listView']['entityList'];
                    for (const entity of entityList) {
                        if (action.ids.has(entity['id'])) {
                            entity['exported'] = true;
                        }
                    }
                })
            );
        }
    }

    @Action(CreateIncomingInventory)
    async createIncomingInventory(ctx: StateContext<EntitiesStateModel>, action: CreateIncomingInventory): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const uploadDocuments = action.uploadDocuments as IMposfile[];
            let entity = await action.resource.api.create(action.entity);
            if (uploadDocuments.length > 0) {
                for (const file of uploadDocuments) {
                    await this._incomingInventoryService.uploadFile(file.label, file.multipartFile, entity.id);
                }
            }
            entity = await action.resource.api.find(entity.id);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );

            if (action.entity?.approveOnCreateOrUpdate === true) {
                this._alertService.showSuccess(`${action.resource.name}.messages.entityAddedAndApproved.message`, null, {
                    discriminator: entity.discriminator
                });
            } else {
                this._alertService.showSuccess(`${action.resource.name}.messages.entityAdded.message`, null, {discriminator: entity.discriminator});
            }
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(UpdateIncomingInventory)
    async updateIncomingInventory(ctx: StateContext<EntitiesStateModel>, action: IUpdateIncomingInventory): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const uploadDocuments = action.uploadDocuments as IMposfile[];
            const deletedDocuments = action.deletedDocuments as IMposfile[];
            let entity = await action.resource.api.update(action.entity);
            if (uploadDocuments && uploadDocuments.length > 0) {
                for (const file of uploadDocuments) {
                    await this._incomingInventoryService.uploadFile(file.label, file.multipartFile, entity.id);
                }
            }

            if (deletedDocuments && deletedDocuments.length > 0) {
                for (const file of deletedDocuments) {
                    await this._purchaseOrderService.deleteFile(entity.id, file.id);
                }
            }

            entity = await action.resource.api.find(entity.id);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );

            if (action.entity?.approveOnCreateOrUpdate === true) {
                this._alertService.showSuccess(`${action.resource.name}.messages.entityUpdatedAndApproved.message`, null, {
                    discriminator: entity.discriminator
                });
            } else {
                this._alertService.showSuccess(`${action.resource.name}.messages.entityUpdated.message`, null, {discriminator: entity.discriminator});
            }
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(CreatePurchaseOrder)
    async createPurchaseOrder(ctx: StateContext<EntitiesStateModel>, action: ICreatePurchaseOrder): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const uploadDocuments = action.uploadDocuments as IMposfile[];
            let entity = await action.resource.api.create(action.entity);
            if (uploadDocuments.length > 0) {
                for (const file of uploadDocuments) {
                    await this._purchaseOrderService.uploadFile(file.label, file.multipartFile, entity.id);
                }
            }
            entity = await action.resource.api.find(entity.id);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            this._alertService.showSuccess(`${action.resource.name}.messages.entityAdded.message`, null, {discriminator: entity.discriminator});
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(ConfirmPurchaseOrder)
    async confirmPurchaseOrder(ctx: StateContext<EntitiesStateModel>, action: ConfirmPurchaseOrder): Promise<void> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const response = await this._purchaseOrderService.confirmPurchaseOrder(action.id);
            if (response.status === 200) {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        const entityView = draft[action.resource.name]['entityView'];
                        entityView.currentEntity['purchaseOrderStatus'] = 'CONFIRMED_APPROVED';
                        entityView.statusSaving = false;
                    })
                );
            }
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(CancelPurchaseOrder)
    async cancelPurchaseOrder(ctx: StateContext<EntitiesStateModel>, action: CancelPurchaseOrder): Promise<void> {
        const response = await this._purchaseOrderService.cancelPurchaseOrder(action.id, action.date, action.time);
        if (response.status === 200) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityList: any[] = draft[action.resource.name]['listView']['entityList'];
                    for (const entity of entityList) {
                        if (entity.id === action.id) {
                            entity.purchaseOrderStatus = PurchaseOrderStatus.CANCELLED;
                        }
                    }
                })
            );
        }
    }

    @Action(UpdatePurchaseOrder)
    async updatePurchaseOrder(ctx: StateContext<EntitiesStateModel>, action: IUpdatePurchaseOrder): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const uploadDocuments = action.uploadDocuments as IMposfile[];
            const deletedDocuments = action.deletedDocuments as IMposfile[];
            let entity = await action.resource.api.update(action.entity);
            if (uploadDocuments && uploadDocuments.length > 0) {
                for (const file of uploadDocuments) {
                    await this._purchaseOrderService.uploadFile(file.label, file.multipartFile, entity.id);
                }
            }

            if (deletedDocuments && deletedDocuments.length > 0) {
                for (const file of deletedDocuments) {
                    await this._purchaseOrderService.deleteFile(entity.id, file.id);
                }
            }

            entity = await action.resource.api.find(entity.id);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            this._alertService.showSuccess(`${action.resource.name}.messages.entityUpdated.message`, null, {discriminator: entity.discriminator});
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(ReceivePurchaseOrder)
    async recievePurchaseOrder(ctx: StateContext<EntitiesStateModel>, action: ReceivePurchaseOrder): Promise<void> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const response = await this._purchaseOrderService.receivePurchaseOrder(action.id);
            if (response.status === 200) {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        const entityView = draft[action.resource.name]['entityView'];
                        entityView.currentEntity['purchaseOrderStatus'] = 'RECEIVE_INVENTORY';
                        entityView.statusSaving = false;
                    })
                );
            }
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(CreatePurchaseInvoice)
    async createPurchaseInvoice(ctx: StateContext<EntitiesStateModel>, action: CreatePurchaseInvoice): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const uploadDocuments = action.uploadDocuments as IMposfile[];
            let entity = await action.resource.api.create(action.entity);
            if (uploadDocuments.length > 0) {
                for (const file of uploadDocuments) {
                    await this._purchaseInvoiceService.uploadFile(file.label, file.multipartFile, entity.id);
                }
            }
            entity = await action.resource.api.find(entity.id);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            this._alertService.showSuccess(`${action.resource.name}.messages.entityAdded.message`, null, {discriminator: entity.discriminator});
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(UpdatePurchaseInvoice)
    async updatePurchaseInvoice(ctx: StateContext<EntitiesStateModel>, action: IUpdatePurchaseInvoice): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const uploadDocuments = action.uploadDocuments as IMposfile[];
            const deletedDocuments = action.deletedDocuments as IMposfile[];
            let entity = await action.resource.api.update(action.entity);
            if (uploadDocuments && uploadDocuments.length > 0) {
                for (const file of uploadDocuments) {
                    await this._purchaseInvoiceService.uploadFile(file.label, file.multipartFile, entity.id);
                }
            }

            if (deletedDocuments && deletedDocuments.length > 0) {
                for (const file of deletedDocuments) {
                    await this._purchaseOrderService.deleteFile(entity.id, file.id);
                }
            }

            entity = await action.resource.api.find(entity.id);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            this._alertService.showSuccess(`${action.resource.name}.messages.entityUpdated.message`, null, {discriminator: entity.discriminator});
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    // Private methods

    private async _fetchEntityList(
        ctx: StateContext<EntitiesStateModel>,
        resource: AbstractEntityResource<any>,
        viewParams: ListViewParams,
        api: (request: any) => Promise<HttpResponse<any[]>>
    ): Promise<any[]> {
        const res = await api(this._apiParams(viewParams));
        const entityList = res.body;
        const totalEntityCount = parseInt(res.headers.get('X-Total-Count'), 10);
        if (entityList.length === 0 && totalEntityCount > 0) {
            const lastPageIndex = this._computeLastPageIndex(viewParams.pageSize, totalEntityCount);
            if (lastPageIndex < viewParams.pageIndex) {
                return this._fetchEntityList(ctx, resource, {...viewParams, pageIndex: lastPageIndex}, api);
            }
        }
        this._updateFetchEntityListState(ctx, resource, entityList, totalEntityCount, viewParams);
        return entityList;
    }

    private _computeLastPageIndex(pageSize: number, totalItems: number): number {
        return Math.ceil(totalItems / pageSize) - 1;
    }

    private _updateFetchEntityListState(
        ctx: StateContext<EntitiesStateModel>,
        resource: AbstractEntityResource<any>,
        entityList: any[],
        totalItems: number,
        viewParams: ListViewParams
    ): void {
        const prevState = ctx.getState();

        const oldSelectedIds = this.retainPreviousScreenFilters ? prevState[resource.name]['listView'].selectedIds : [];
        ctx.setState({
            ...prevState,
            [resource.name]: {
                ...prevState[resource.name],
                listView: {
                    ...prevState[resource.name].listView,
                    initialized: true,
                    totalItems,
                    viewParams,
                    entityList,
                    statusLoading: false,
                    selectedIds: oldSelectedIds
                }
            }
        });

        if (!this.retainPreviousScreenFilters) {
            viewParams.filter = '';
            viewParams.filterParams = null;
        }
        console.log(`[EntityState] Entity list state updated for ${resource.name}`);
    }

    private _apiParams(viewParams: ListViewParams): any {
        const returnValue = {
            page: viewParams.pageIndex,
            size: viewParams.pageSize,
            sort: viewParams.sortColumn + ',' + viewParams.sortDirection,
            query: viewParams.filter
        };
        const filters = viewParams.filterParams;
        if (filters) {
            for (const filter of filters) {
                if (filter.currentValue) {
                    returnValue[filter.columnName] = filter.currentValue.toString();
                }
            }
        }
        return returnValue;
    }

    @Action(CancelInvoice)
    async cancelInvoice(ctx: StateContext<EntitiesStateModel>, action: CancelInvoice): Promise<void> {
        const response = await this._invoiceService.cancelInvoice(action.id);
        if (response.status === 200) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityList: any[] = draft[action.resource.name]['listView']['entityList'];
                    for (const entity of entityList) {
                        if (entity.id === action.id) {
                            entity.invoiceStatus = 'CANCELLED';
                        }
                    }
                })
            );
        }
    }

    @Action(CreateServiceOrder)
    async createServiceOrder(ctx: StateContext<EntitiesStateModel>, action: ICreateServiceOrder): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const uploadDocuments = action.uploadDocuments as IMposfile[];
            let entity = await action.resource.api.create(action.entity);
            if (uploadDocuments.length > 0) {
                for (const file of uploadDocuments) {
                    await this._serviceOrderService.uploadFile(file.label, file.multipartFile, entity.id);
                }
            }
            entity = await action.resource.api.find(entity.id);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            this._alertService.showSuccess(`${action.resource.name}.messages.entityAdded.message`, null, {discriminator: entity.discriminator});
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(UpdateServiceOrder)
    async updateServiceOrder(ctx: StateContext<EntitiesStateModel>, action: IUpdateServiceOrder): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const uploadDocuments = action.uploadDocuments as IMposfile[];
            const deletedDocuments = action.deletedDocuments as IMposfile[];
            let entity = await action.resource.api.update(action.entity);
            if (uploadDocuments && uploadDocuments.length > 0) {
                for (const file of uploadDocuments) {
                    await this._serviceOrderService.uploadFile(file.label, file.multipartFile, entity.id);
                }
            }

            if (deletedDocuments && deletedDocuments.length > 0) {
                for (const file of deletedDocuments) {
                    await this._serviceOrderService.deleteFile(entity.id, file.id);
                }
            }

            entity = await action.resource.api.find(entity.id);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            this._alertService.showSuccess(`${action.resource.name}.messages.entityUpdated.message`, null, {discriminator: entity.discriminator});
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(ServicedServiceOrder)
    async servicedServiceOrder(ctx: StateContext<EntitiesStateModel>, action: ServicedServiceOrder): Promise<void> {
        const response = await this._serviceOrderService.servicedServiceOrder(action.id);
        if (response.status === 200) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity['serviceOrderStatus'] = 'SERVICED';
                })
            );
        }
    }

    @Action(UncancelSaleOrder)
    async unCancelSaleOrder(ctx: StateContext<EntitiesStateModel>, action: UncancelSaleOrder): Promise<void> {
        const response = await this._saleOrderService.unCancelSaleOrder(action.id, action.date, action.time);
        if (response.status === 200) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityList: any[] = draft[action.resource.name]['listView']['entityList'];
                    for (const entity of entityList) {
                        if (entity.id === action.id) {
                            entity.saleOrderStatus = response.body['saleOrderStatus'];
                        }
                    }
                })
            );
        }
    }

    @Action(UnCancelInvoice)
    async unCancelInvoice(ctx: StateContext<EntitiesStateModel>, action: UnCancelInvoice): Promise<void> {
        const response = await this._invoiceService.unCancelInvoice(action.id);
        if (response.status === 200) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityList: any[] = draft[action.resource.name]['listView']['entityList'];
                    for (const entity of entityList) {
                        if (entity.id === action.id) {
                            entity.invoiceStatus = response.body['invoiceStatus'];
                        }
                    }
                })
            );
        }
    }

    @Action(UpdateInvoiceAllocated)
    async UpdateInvoiceAllocated(ctx: StateContext<EntitiesStateModel>, action: UpdateInvoiceAllocated): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityList = draft[action.resource.name]['listView'].entityList as any[];
                entityList.forEach((data: IInvoice, index: number) => {
                    if (data.id === action.entity.id) {
                        entityList[index].unpaidTotal = action.entity.unpaidTotal;
                    }
                });
            })
        );
    }
}
