import {Route} from 'app/blocks/model/route.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {ISchedule, Schedule} from 'app/blocks/model/schedule.model';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class ScheduleService extends EntityCrudService<ISchedule> {
    constructor(http: HttpClient) {
        super(http, Resource.ROUTE_SCHEDULE_CRUD, (obj?) => new Schedule(obj));
    }

    public createSchedule = (schedule: ISchedule): Promise<HttpResponse<any>> => {
        const REQUEST_URI = `${SERVER_API_URL}api/rdm/route-schedules`;
        console.log('Resource url Data :::', REQUEST_URI);
        return this.http.post(REQUEST_URI, schedule, {observe: 'response'}).toPromise();
    };
}
