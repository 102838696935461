import {SettingsService} from 'app/common/settings.service';
import {ENABLE_DRIVER_ACCESS_CODES} from 'app/constants/setting';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {Injectable} from '@angular/core';
import {Actions, ofActionSuccessful, Store} from '@ngxs/store';

import {DeleteEntity} from 'app/blocks/store/actions/entity-actions';
import {GetEntityList} from 'app/blocks/store/actions/entity-actions';
import {GetConfig} from 'app/blocks/store/actions/common-actions';
import {CommonState} from 'app/blocks/store/states/common-state';
@Injectable({
    providedIn: 'root'
})
export class EntityActionHandler {
    constructor(
        private _store: Store,
        private _actions: Actions,
        public commonState: CommonState,
        private _fuseNavigationService: FuseNavigationService,
        private settingsService: SettingsService
    ) {
        this.init();
    }

    init(): void {
        this._actions.pipe(ofActionSuccessful(DeleteEntity)).subscribe((action: DeleteEntity) => {
            console.log(`[EntityActionHandler] Firing reload of ${action.resource.name} list due to a delete`);
            this._store.dispatch(new GetEntityList(action.resource));
        });

        // this._actions.pipe(ofActionCompleted(GetEntityList)).subscribe(({ action }) => {
        //     console.log(`[EntityActionHandler] Setting statuses to false for ${action.resource.name}`);
        //     this._store.dispatch(action.resource.action.forGetEntityList());
        // });

        // this._actions.pipe(ofActionDispatched(...actionTypes)).subscribe((action: IEntityAction) => {
        //     console.log(`                               ============= Action fired with payload: ==============`);
        //     console.log(action);
        // });

        // this._store.select(state => state).subscribe(state => {
        //     console.log('                                 <<<<<<<<<<<<<<<< APP STATE >>>>>>>>>>>>>>');
        //     console.log(state);
        // });
    }
}
