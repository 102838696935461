import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {ICurrency} from 'app/blocks/model/currency.model';
import {CompanyService} from 'app/blocks/service/api/company.service';
import {SuperUserService} from 'app/core/auth/super-user.service';
import {RoleService} from 'app/blocks/service/api/role.service';
import {GetConfig, TrackAsyncRequest} from 'app/blocks/store/actions/common-actions';
import produce from 'immer';
import {ASYNC_TASKS, COMMANDS, DEFAULT_CURRENCY, ROLE_SETTINGS, TENANT} from 'app/app.constants';
import {COMPANY_SETTINGS} from 'app/app.constants';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {AccountService} from 'app/core/auth/account.service';
import {Subject, takeUntil, timer} from 'rxjs';
import {AsyncRequestService} from 'app/blocks/service/api/async_request.service';

export interface CommonStateModel {
    [DEFAULT_CURRENCY]: ICurrency;
    [COMPANY_SETTINGS]: any;
    [COMMANDS]: any;
}

export function getDefaultCommonState(): CommonStateModel {
    return {
        [DEFAULT_CURRENCY]: null,
        [COMPANY_SETTINGS]: {},
        [COMMANDS]: []
    };
}

@State<CommonStateModel>({
    name: 'common',
    defaults: getDefaultCommonState()
})
@Injectable({
    providedIn: 'root'
})
export class CommonState {
    isSuperuser = false;

    constructor(
        private _companyService: CompanyService,
        private _fuseNavigationService: FuseNavigationService,
        private _accountService: AccountService,
        private superUserService: SuperUserService,
        private asyncRequestService: AsyncRequestService,
        private roleService: RoleService
    ) {}

    private destroy$ = new Subject<void>();

    @Action(TrackAsyncRequest)
    async getAyncRequestInProgress(ctx: StateContext<CommonStateModel>, action: TrackAsyncRequest): Promise<void> {
        localStorage.setItem('bulkActionStarted', String(true));
        timer(0, 5000)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                if (localStorage.getItem('bulkActionStarted') == 'true' && !this.isSuperuser) {
                    this.asyncRequestService.getInProgessAsyncRequest().then((result) => {
                        if (result.body.length <= 0) {
                            localStorage.setItem('bulkActionStarted', String(false));
                        }
                        ctx.setState(
                            produce(ctx.getState(), (draft) => {
                                draft[ASYNC_TASKS] = result.body;
                            })
                        );
                    });
                } else {
                    this.destroy$.next();
                    this.destroy$.complete();
                }
            });
    }

    @Action(GetConfig)
    async getConfigForCompany(ctx: StateContext<CommonStateModel>, action: GetConfig): Promise<void> {
        this.isSuperuser = this.superUserService.getIsSuperUser();
        const config = this.isSuperuser ? {} : await this._companyService.getConfig();
        if (config) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    draft[DEFAULT_CURRENCY] = config.currency;
                    draft[COMPANY_SETTINGS] = config.settings;
                    this._accountService
                        .get()
                        .pipe()
                        .subscribe((response) => {
                            if (response.status === 200) {
                                const account = response.body as any;
                                if (account.franchise) {
                                    this._fuseNavigationService.updateNavigationItem('company', {
                                        hidden: true
                                    });
                                    this._fuseNavigationService.updateNavigationItem('users', {
                                        hidden: true
                                    });
                                    this._fuseNavigationService.updateNavigationItem('user-configuration', {
                                        hidden: true
                                    });
                                }
                            }
                        });
                })
            );
        }
        return config;
    }
}
