import {ILocationPoint} from 'app/blocks/model/location-point.model';
import {INote} from 'app/blocks/model/note.model';
import {ITimeWindow} from 'app/blocks/model/time-window.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';
import {DiTime} from 'app/blocks/util/di-time';

export interface IHaulierOrder extends IBaseEntity {
    id?: number;
    orderNo?: string;
    orderStatus?: string;
    subContracted?: boolean;
    subContractNumber?: string;
    collectionPlanned?: boolean;
    deliveryPlanned?: boolean;
    delivered?: boolean;
    invoiceStatus?: string;
    requestDateTime?: any;
    collectDuringOfficeHours?: boolean;
    deliverDuringOfficeHours?: boolean;
    docketNumber?: string;
    loadNumber?: string;
    trafficOverride?: boolean;
    rate?: number;
    foreignRate?: number;
    fuelSurcharge?: number;
    foreignFuelSurcharge?: number;
    collectionWindow?: ITimeWindow;
    plannedWindow?: ITimeWindow;
    deliveryWindow?: ITimeWindow;
    instructions?: INote;
    trafficNotes?: INote;
    collectionNotes?: INote;
    deliveryNotes?: INote;
    internalComments?: INote;
    // baseGoodsType?: IBaseGoodsType;
    // customer?: IOrganisation;
    deliveryPoint?: ILocationPoint;
    collectionPoint?: ILocationPoint;
    // requestTakenBy?: IPerson;
    // customGoodsType?: ICustomGoodsType;
    // businessType?: IBusinessType;
    // serviceLevel?: IServiceLevel;
    // exchangeRate?: IExchangeRate;
}

export class HaulierOrder extends BaseEntity implements IHaulierOrder {
    public id?: number;
    public orderNo?: string;
    public orderStatus?: string;
    public subContracted?: boolean;
    public subContractNumber?: string;
    public collectionPlanned?: boolean;
    public deliveryPlanned?: boolean;
    public delivered?: boolean;
    public invoiceStatus?: string;
    public requestDateTime?: any;
    public collectDuringOfficeHours?: boolean;
    public deliverDuringOfficeHours?: boolean;
    public docketNumber?: string;
    public loadNumber?: string;
    public trafficOverride?: boolean;
    public rate?: number;
    public foreignRate?: number;
    public fuelSurcharge?: number;
    public foreignFuelSurcharge?: number;
    public collectionWindow?: ITimeWindow;
    public plannedWindow?: ITimeWindow;
    public deliveryWindow?: ITimeWindow;
    public instructions?: INote;
    public trafficNotes?: INote;
    public collectionNotes?: INote;
    public deliveryNotes?: INote;
    public internalComments?: INote;
    // public baseGoodsType?: IBaseGoodsType;
    // public customer?: IOrganisation;
    public deliveryPoint?: ILocationPoint;
    public collectionPoint?: ILocationPoint;
    // public requestTakenBy?: IPerson;
    // public customGoodsType?: ICustomGoodsType;
    // public businessType?: IBusinessType;
    // public serviceLevel?: IServiceLevel;
    // public exchangeRate?: IExchangeRate;

    constructor(haulierOrder?) {
        super();
        if (haulierOrder) {
            this.id = haulierOrder.id;
            this.orderNo = haulierOrder.orderNo;
            this.orderStatus = haulierOrder.orderStatus;
            this.subContracted = haulierOrder.subContracted;
            this.subContractNumber = haulierOrder.subContractNumber;
            this.collectionPlanned = haulierOrder.collectionPlanned;
            this.deliveryPlanned = haulierOrder.deliveryPlanned;
            this.delivered = haulierOrder.delivered;
            this.invoiceStatus = haulierOrder.invoiceStatus;
            this.requestDateTime = DiTime.newInstance(haulierOrder.requestDateTime);
            this.collectDuringOfficeHours = haulierOrder.collectDuringOfficeHours;
            this.deliverDuringOfficeHours = haulierOrder.deliverDuringOfficeHours;
            this.docketNumber = haulierOrder.docketNumber;
            this.loadNumber = haulierOrder.loadNumber;
            this.trafficOverride = haulierOrder.trafficOverride;
            this.rate = haulierOrder.rate;
            this.foreignRate = haulierOrder.foreignRate;
            this.fuelSurcharge = haulierOrder.fuelSurcharge;
            this.foreignFuelSurcharge = haulierOrder.foreignFuelSurcharge;
            this.collectionWindow = haulierOrder.collectionWindow;
            this.plannedWindow = haulierOrder.plannedWindow;
            this.deliveryWindow = haulierOrder.deliveryWindow;
            this.instructions = haulierOrder.instructions;
            this.trafficNotes = haulierOrder.trafficNotes;
            this.collectionNotes = haulierOrder.collectionNotes;
            this.trafficNotes = haulierOrder.trafficNotes;
            this.collectionNotes = haulierOrder.collectionNotes;
            this.deliveryNotes = haulierOrder.deliveryNotes;
            this.internalComments = haulierOrder.internalComments;
            // this.baseGoodsType = haulierOrder.baseGoodsType;
            // this.customer = haulierOrder.customer;
            this.deliveryPoint = haulierOrder.deliveryPoint;
            this.collectionPoint = haulierOrder.collectionPoint;
            // this.requestTakenBy = haulierOrder.requestTakenBy;
            // this.customGoodsType = haulierOrder.customGoodsType;
            // this.businessType = haulierOrder.businessType;
            // this.serviceLevel = haulierOrder.serviceLevel;
            // this.exchangeRate = haulierOrder.exchangeRate;
        } else {
        }
    }
    get discriminator(): any {
        return this.orderNo;
    }

    clone(): IHaulierOrder {
        return new HaulierOrder(this);
    }
}
